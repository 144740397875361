import { useEffect, useState } from "react";
import confetti from "canvas-confetti";
import {setSessionData, decode, appStore, hasCookieAndStorage} from '@cmj526/made-for-us';
import { appID } from "../../shared/app-service";



export const useMainHook = () => {
  const [pageData, setPageData] = useState({});
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    hasCookieAndStorage(appID,"main")
      ? setPageData(decode(appStore.get(`${appID}main`)))
      : setSessionData(appID,"main", "/api/main", setPageData);
  }, []);

  useEffect(() => {
    var duration = 10 * 1000;
    var end = Date.now() + duration;

    (function frame() {
      // launch a few confetti from the left edge
      confetti({
        particleCount: 7,
        angle: 60,
        colors: ["#29303A", "#CACACA", "#D0992E"],
        spread: 70,
        origin: { x: 0 },
      });
      // and launch a few from the right edge
      confetti({
        particleCount: 7,
        angle: 120,
        colors: ["#29303A", "#CACACA", "#D0992E"],
        spread: 100,
        origin: { x: 1 },
      });

      // keep going until we are out of time
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();

    window.addEventListener("scroll", () => {
      setIsScrolling(true);
    });
  }, [isScrolling]);


  return {
    pageData,
  };
};
