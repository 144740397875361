export const mobileSmall = "320px";
export const mobileIphoneX = "375px";
export const mobileBreakpoint = "768px";
export const mobile = {
  iPhoneX: {
    width: "1125px",
    height: "2436px",
  },
  medium: {
    width: "500px",
    height: "488px",
  },
  small: {
    width: "320px",
    height: "533px",
  },
};

export const iphone = {
  xr: {
    width: "414px",
    height: "896px",
  },
  xs: {
    width: "375px",
    height: "812px",
  },
  xsMax: {
    width: "414px",
    height: "896px",
  },
  x: {
    width: "375px",
    height: "812px",
  },
  eightPlus: {
    width: "414px",
    height: "736px",
  },
  eight: {
    width: "375px",
    height: "667px",
  },
  sevenPlus: {
    width: "414px",
    height: "736px",
  },
  seven: {
    width: "375px",
    height: "667px",
  },
  sixPlus: {
    width: "414px",
    height: "736px",
  },
  six: {
    width: "375px",
    height: "667px",
  },
  five: {
    width: "320px",
    height: "568px",
  },
};

/*
  Laptop Sizes
 */
export const Laptop1280 = {
  width: "1280px",
  height: "800px",
};

export const screen = {
  360: {
    width: "360px",
    height: "640px",
  },
  375: {
    width: "375px",
    height: "667px",
  },
  840: {
    width: "840px",
    height: "667px",
  },
  960: {
    width: "960px",
    height: "667px",
  },
  1280: {
    width: "1280px",
    height: "800px",
  },
  1366: {
    width: "1366px",
    height: "768px",
  },
  1440: {
    width: "1440px",
    height: "900px",
  },
  1660: {
    width: "1660px",
    height: "900px",
  },
  1920: {
    width: "1920px",
    height: "1080px",
  },
};
