import React from "react";
import styled, { keyframes } from "styled-components";
import "./Clip.scss";

const StyledContainer = styled.div`
  display: flex;
  position: relative;
`;

const StretchFull = keyframes`
  0% {
    height: 100vh;
  }
  100% {
    height: 500px;
  }
`;

const ClipImg = styled.div`
  position: absolute;
  background: url("${({ img }) => img}");
  background-repeat: no-repeat;
  background-position: 70% 20%;
  background-size: cover;
  transform-origin: center;
  width: 100%;
  height: 100vh;
  top: 0;
  animation: ${StretchFull} 0.8s 7s ease-in-out forwards;
  transition: all 5s ease-in-out;
  z-index: 1;
`;

const Clip = ({ clipImg }) => {
  return (
    <StyledContainer>
      <ClipImg id="background" img={clipImg}></ClipImg>
      <ClipImg id="clip3" img={clipImg}></ClipImg>
      <ClipImg id="clip4" img={clipImg}></ClipImg>
      <ClipImg id="clipa" img={clipImg}></ClipImg>
      <ClipImg id="clip1" img={clipImg}></ClipImg>
      <ClipImg id="clip2" img={clipImg}></ClipImg>
      <ClipImg id="clipb" img={clipImg}></ClipImg>
    </StyledContainer>
  );
};

export default Clip;
