import { useEffect, useState } from "react";
import { appStore, decode, hasCookieAndStorage, setSessionData } from "@cmj526/made-for-us";
import { appID } from "../../shared/app-service";


export const useAboutHook = () => {
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    hasCookieAndStorage(appID,"about")
      ? setPageData(decode(appStore.get(`${appID}about`)))
      : setSessionData(appID,"about", "/api/about", setPageData);
  }, []);

  return {
    pageData,
  };
};
