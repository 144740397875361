
import React from 'react';
import {useBookHook} from './Hooks';
import BookComponent from './Component';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Book = () => {
  return (
    <>
    <Header />
    <BookComponent {...useBookHook()} />
    <Footer />
    </>
  )
}
export default Book;           
                