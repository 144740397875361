import React from "react";
import { Link } from "react-router-dom";
import "./BreadCrumb.scss";

const Breadcrumb = ({ crumb }) => {
  console.log("breadcrumb");
  return (
    <div className="bread">
      <div className="bread">
        <Link to="/" className="crumb-link">
          Home
        </Link>{" "}
        <i className="fas fa-angle-double-right breadcrumb-link"></i>
        <span className="breadcrumb-name">{crumb}</span>
      </div>
    </div>
  );
};

export default Breadcrumb;
