import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { handleScrollAnimation } from "../Helper/Animation";
import "./People.scss";

const FadeBkIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {opacity: 1;
    transform: translateY(0px);
  }
`;

const StrecthLeftOut = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const StrecthRightOut = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-attachment: fixed;
  background-image: url("https://ljc-site.s3.us-east-2.amazonaws.com/img/achievement.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  opacity: 0;
  animation: ${FadeBkIn} 1s 8s ease-in-out forwards;
`;

const LeftCard = styled.div`
  width: 500px;
  margin-top: 50px;
  background-color: #f7f7f7;
  height: 40rem;
  opacity: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  ${({ shouldAnimate }) =>
    shouldAnimate &&
    css`
      animation: ${StrecthLeftOut} 1s ease-in-out forwards;
    `}
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media screen and (max-width: 1680px) {
    width: 300px;
    height: 450px;
  }

  @media (max-width: 500px) {
    height: 25rem;
    margin: 2rem;
  }
`;

const MiddleCard = styled.div`
  width: 400px;
  background-color: #f7f7f7;
  height: 40rem;
  border-radius: 5px;
  -webkit-font-smoothing: antialiased;
  z-index: 1;
  transform: translateY(-1px);
  box-shadow: 0 16px 64px 0 rgba(0, 35, 70, 0.2);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media screen and (max-width: 1680px) {
    width: 300px;
    height: 550px;
  }

  @media (max-width: 500px) {
    height: 25rem;
    margin: 2rem;
  }
`;

const RightCard = styled.div`
  width: 500px;
  margin-top: 50px;
  background-color: #f7f7f7;
  height: 40rem;
  opacity: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  ${({ shouldAnimate }) =>
    shouldAnimate &&
    css`
      animation: ${StrecthRightOut} 1s ease-in-out forwards;
    `}
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media screen and (max-width: 1680px) {
    width: 300px;
    height: 450px;
  }

  @media (max-width: 500px) {
    height: 25rem;
    margin: 2rem;
  }
`;

const PeopleSection = () => {
  const [startAnimation, setAnimation] = useState(false);
  const el = document.getElementById("middle-card");

  window.addEventListener("scroll", () => {
    setAnimation(handleScrollAnimation(el));
  });

  return (
    <StyledContainer>
      <div className="section-overlay"></div>
      <div className="people-header-container">
        <div className="people-header-title">Our Model of Success</div>
        <div className="people-header-section">
          Our belief is that in order to launch into one's level of greatness,
          you must harness the power of your potential. We are ready to assist
          you on that journey. Your only limitations are the ones you create and
          the ones you allow. Make your next move count when you connect with
          Lynnjac Consulting because your future is waiting.
        </div>
        <div className="people-header-arrow">
          <i className="fas fa-arrow-down people-arrow"></i>
        </div>
      </div>
      <div className="people-card-container">
        <LeftCard shouldAnimate={startAnimation}>
          <div className="img-text-1">People</div>
          <div className="img-container">
            <img
              className="people-img"
              src="https://ljc-site.s3.us-east-2.amazonaws.com/img/people.png"
              alt=""
            />
            <div className="img-description">
              The focus is on organizations, team, groups, and indiviuals who
              are the people meant to be unstoppable in perfomance,
              productivity, and perserverance.
            </div>
          </div>
        </LeftCard>
        <MiddleCard id="middle-card">
          <div className="img-container">
            <div className="img-text-2">Potential</div>
            <img
              className="people-img"
              src="https://ljc-site.s3.us-east-2.amazonaws.com/img/potential.png"
              alt=""
            />
            <div className="img-description">
              The goal is for others to recognize their limitless potential when
              they tap into the resources available.
            </div>
          </div>
        </MiddleCard>
        <RightCard shouldAnimate={startAnimation}>
          <div className="img-container">
            <div className="img-text-3">Accelerator</div>
            <img
              className="people-img"
              src="https://ljc-site.s3.us-east-2.amazonaws.com/img/potential-2.jpeg"
              alt=""
            />
            <div className="img-description">
              The way to accelerated growth is through questions and analysis to
              mitigate problems impeding peak potential.
            </div>
          </div>
        </RightCard>
      </div>
    </StyledContainer>
  );
};

export default PeopleSection;
