import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Carousel from "../Carousel/Carousel";
import { handleNext } from "../../shared/app-service";
import { mobileBreakpoint } from "../Helper/ScreenSize";
import "./Quote.scss";

const FadeIn = keyframes`
  0% {opacity: 0}
  100% {opacity: 1}
`;

export const ChevronLeft = styled.img`
  height: 40px;
  width: 40px;
  position: absolute;
  top: 45%;
  z-index: 2;
  cursor: pointer;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.9));
  left: 5%;
  @media (max-width: ${mobileBreakpoint}) {
    left: 2%;
  }
`;

export const ChevronRight = styled.img`
  height: 40px;
  width: 40px;
  color: white;
  position: absolute;
  top: 45%;
  z-index: 2;
  cursor: pointer;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.9));
  right: 5%;
  @media (max-width: ${mobileBreakpoint}) {
    right: 2%;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  background-image: url("https://ljc-site.s3.us-east-2.amazonaws.com/img/purple.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  opacity: 0;
  animation: ${FadeIn} 1s 8s ease-in-out forwards;
`;

const QuoteSection = () => {
  const carouselImgs = [
    "https://ljc-site.s3.us-east-2.amazonaws.com/img/Quote-1.jpeg",
    "https://ljc-site.s3.us-east-2.amazonaws.com/img/Quote-2.jpeg",
    "https://ljc-site.s3.us-east-2.amazonaws.com/img/Quote-3.jpeg",
  ];

  const handleNextSlide = () => {
    console.log("slide right");
    handleNext.next(15);
  };

  const handlePreviousSlide = () => {
    console.log("slide left");
  };

  useEffect(() => {
    return () => {};
  }, []);
  return (
    <StyledContainer>
      <ChevronLeft
        onClick={() => handlePreviousSlide()}
        src="https://ljc-site.s3.us-east-2.amazonaws.com/img/left-chevron.png"
      />
      <div className="quote_container">
        <Carousel imgs={carouselImgs} />
      </div>
      <ChevronRight
        onClick={() => handleNextSlide()}
        src="https://ljc-site.s3.us-east-2.amazonaws.com/img/right-chevron.png"
      />
    </StyledContainer>
  );
};

export default QuoteSection;
