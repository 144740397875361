import React from "react";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import Presentationmodal from "../../components/PresentationModal/Presentationmodal";
import "./Styles.scss";
import Loader from "../../components/Loader/Loader";

const CoursesComponent = ({
  pageData,
  modalShow,
  modalSlideName,
  modalSlides,
  setModalShow,
  presentModal,
  videoEl,
  history,
  handleRoute,
}) => {
  return pageData ? (
    <>
      <div className="courses-container">
        <Presentationmodal
          show={modalShow}
          slides={modalSlides}
          slideName={modalSlideName}
          onHide={() => setModalShow(false)}
        />

        <div className="about-container">
          <div className="about-header">Courses & Workshops</div>
        </div>
        <BreadCrumb crumb="Courses & Workshops" />
        <div className="training-jumbotron">
          <div className="training-overlay"> </div>
          <video
            autoPlay
            playsInline
            loop
            muted
            alt="All the devices"
            ref={videoEl}
            src={pageData.courses?.bkgVideo}
          ></video>
          <div className="blog-info">
            Make the move to launch you and your team into next level learning.
            Content topics are available to address a variety of your workforce
            learning needs. Lynnjac Consulting partners with you to provide a
            solution performance/productivity for today and your future
            readiness. Reach out today to learn about options for a course,
            workshop, retreat, conference session, keynote presentation, or
            other speaking event.
          </div>

          <div className="training-cards-container">
            <div className="blog-card">
              <div className="meta">
                <div className="photo"></div>
                <ul className="details"></ul>
              </div>
              <div className="description">
                <h1>Adaptability</h1>
                <h2>Begin learning for your team</h2>
                <p>
                  {" "}
                  Organizations across most industries are finding that survival
                  with today’s pace of change requires adaptability. This
                  presentation gives a highlight of the variables that factor
                  into a competitive advantage for sustainability.{" "}
                </p>
                <p className="read-more">
                  <a href="#modal" onClick={() => presentModal("Adaptability")}>
                    Read More
                  </a>
                </p>
              </div>
            </div>

            <div className="blog-card">
              <div className="meta">
                <div className="photo-2"></div>
                <ul className="details-2"></ul>
              </div>
              <div className="description">
                <h1>Change Management</h1>
                <h2>Begin learning for your team</h2>
                <p>
                  {" "}
                  Even before COVID-19, there were signs. You can’t handle the
                  truth that it’s time to change. This presentation deals with
                  recognizing indicators signaling the need for change. It also
                  shares insights to managing change efforts.{" "}
                </p>
                <p className="read-more">
                  <a
                    href="#modal"
                    onClick={() => presentModal("Change Management")}
                  >
                    Read More
                  </a>
                </p>
              </div>
            </div>

            <div className="blog-card">
              <div className="meta">
                <div className="photo-3"></div>
                <ul className="details-3"></ul>
              </div>
              <div className="description">
                <h1>Diverisity, Equity and Inclusion (DEI)</h1>
                <h2>Begin learning for your team</h2>
                <p>
                  {" "}
                  Coloring Outside the Lines launches into the foundation of
                  diversity, equity, and inclusion. This is a presentation based
                  on a sample framework with the 3Cs—Culture, Connections, and
                  Community Impact to drive a DEI strategy.
                </p>
                <p className="read-more">
                  <a href="#modal" onClick={() => presentModal("DEI")}>
                    Read More
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="more-training">
          <div
            className="contact-offer"
            onClick={() => handleRoute("contact", "locally", history)}
          >
            Contact us today!
          </div>

          <div className="course-bar-section">
            <div className="courses-bar"></div>
            <div className="course-see-below">See more courses below</div>
          </div>
          <div className="more-training-container">
            <div className="blog-card">
              <div className="meta">
                <div className="photo-4"></div>
                <ul className="details-4"></ul>
              </div>
              <div className="description">
                <h1>HR</h1>
                <h2>Begin learning for your team</h2>
                <p>
                  {" "}
                  Leading with the Right Qs in HR is an introduction to the
                  world of Qs. It ventures into how they apply to HR leadership
                  and operations. Here, we connect the dots and get into some
                  steps to raise the Q level. As we look at what the future
                  holds for HR, it looks bright with opportunity for high Qs.
                </p>
                <p className="read-more">
                  <a href="#modal" onClick={() => presentModal("HR")}>
                    Read More
                  </a>
                </p>
              </div>
            </div>

            <div className="blog-card">
              <div className="meta">
                <div className="photo-6"></div>
                <ul className="details"></ul>
              </div>
              <div className="description">
                <h1>Leadership</h1>
                <h2>Begin learning for your team</h2>
                <p>
                  {" "}
                  Today’s leader must navigate circumstances of uncertainty
                  while leading others and delivering results. This presentation
                  shares what it really means to be a resilient and innovative
                  leader.{" "}
                </p>
                <p className="read-more">
                  <a href="#modal" onClick={() => presentModal("Leadership")}>
                    Read More
                  </a>
                </p>
              </div>
            </div>

            <div className="blog-card">
              <div className="meta">
                <div className="photo-5"></div>
                <ul className="details"></ul>
              </div>
              <div className="description">
                <h1>Learning & Development</h1>
                <h2>Begin learning for your team</h2>
                <p>
                  {" "}
                  Mastering Adaptability for Future-Ready L&D prompts attention
                  to emerging trends for the learning and development field.
                  This presentation aligns key leadership skills to respond to
                  dynamic change for future readiness.{" "}
                </p>
                <p className="read-more">
                  <a
                    href="#modal"
                    onClick={() => presentModal("Learning and Development")}
                  >
                    Read More
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default CoursesComponent;
