import React from "react";
import { useNavigate } from "react-router-dom";
import {
  handleRoute
} from "@cmj526/made-for-us";
import styled, { keyframes } from "styled-components";
import "./Service.scss";

const FadeIn = keyframes`
  0% {opacity: 0}
  100% {opacity: 1}
`;

const StyledContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #551fbd;
  width: 100%;
  opacity: 0;
  animation: ${FadeIn} 1s 8s ease-in-out forwards;
`;

const ServiceSection = () => {
  let history = useNavigate();
  return (
    <StyledContainer>
      <div className="card-grid-container">
        <div className="card-service-title">
          <div className="arrow-icon">
            <div className="offering-container">
              <span className="offering-top-title">What we're</span>
              <span className="offering-title">Offering</span>{" "}
            </div>
            <i className="fas fa-arrow-right srv-arrow service-arrow"></i>
          </div>
        </div>
        <div className="container">
          <div className="cardWrap" onClick={() => handleRoute("/training", "locally", history)}>
            <div className="card">
              <div className="cardBg"></div>
              <div className="cardInfo">
                <h3 className="cardTitle">Training Materials</h3>
                <p>Grab your 2022 Training materials available now.</p>
              </div>
            </div>
          </div>
          <div className="cardWrap" onClick={() =>  handleRoute("/book", "locally", history)}>
            <div className="card">
              <div className="cardBg1"></div>
              <div className="cardInfo">
                <h3 className="cardTitle">Publishings & Literary</h3>
                <p>Buy Books, journals and products available now.</p>
              </div>
            </div>
          </div>
          <div className="cardWrap" onClick={() =>  handleRoute("/courses", "locally", history)}>
            <div className="card">
              <div className="cardBg2"></div>
              <div className="cardInfo">
                <h3 className="cardTitle">Courses & Workshops</h3>
                <p>See available schedule for 2022.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-offer" onClick={() =>  handleRoute("/contact", "locally", history)}>
        Contact Lynnjac Consulting today to meet your training needs!
      </div>
    </StyledContainer>
  );
};

export default ServiceSection;
