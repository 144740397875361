import React, { useState, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { mobileBreakpoint } from "../Helper/ScreenSize";
import "./Carousel.scss";

export const CarouselWrapper = styled.div`
  display: flex;
  max-width: 100%;
  //   @media (max-width: 1120px) {
  //     height: 600px;
  //   }

  //   @media (max-width: 560px) {
  //     height: 300px;
  //   }

  //   @media (max-width: 414px) {
  //     height: 300px;
  //   }
`;

export const fade = keyframes`
  from {opacity: .4} 
  to {opacity: 1}
`;
export const StyledCarousel = styled.div`
  ${(props) =>
    props.active
      ? css`
          transition: all 1s ease-in-out;
          animation: ${fade} 1s ease-in-out forwards;
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          position: relative;
          object-fit: contain;
          border: none;
        `
      : css`
          display: none;
        `}
`;

export const DotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  left: calc(1100px / 2);
  margin-top: -5px;
  z-index: 2;
  @media (max-width: ${mobileBreakpoint}) {
    margin-top: 10px;
  }
`;

export const StyledRectangle = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  z-index: 2;
`;

export const StyledRectangleDot = styled.div`
  ${(props) =>
    props.active
      ? css`
          animation: ${fade} 1s ease-in-out forwards;
          width: 24px;
          height: 4px;
          border-radius: 2px;
          background-color: white;
          margin-left: 5px;
          margin-right: 5px;
          z-index: 2;
          @media (max-width: ${mobileBreakpoint}) {
            background-color: black;
          }
        `
      : css`
          height: 4px;
          width: 4px;
          margin: 0 2px;
          background-color: white;
          border-radius: 50%;
          display: inline-block;
          transition: background-color 0.6s ease;
          z-index: 2;
          @media (max-width: ${mobileBreakpoint}) {
            background-color: #e0e0e0;
          }
        `}
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 350px;
  background-size: cover;
  transform: translateY(-1px);
  //   box-shadow: 0 16px 64px 0 rgba(0, 35, 70, 0.2);
  border-radius: 4px;
  overflow: hidden;

  @media screen and (max-width: 1680px) {
    height: 250px;
  }

  @media (max-width: ${mobileBreakpoint}) {
    width: 100%;
    height: 500px;
  }

  @media (max-width: 560px) {
    height: 300px;
  }

  @media (max-width: 414px) {
    height: 300px;
  }
`;

export const slideInFrames = keyframes`
  from {opacity: 0; transform: translateX(20px)}
  to {opacity: 1; transform: translateX(0)}
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  transition: all 1s ease-in-out;
  animation: ${slideInFrames} 3s ease-in-out;
  border-radius: 4px;
  overflow: hidden;
`;
const Carousel = ({ imgs }) => {
  const [slideCount, setSlideCount] = useState(0);

  const changeSlideCount = (subscribe) => {
    if (subscribe) {
      setTimeout(() => {
        let count = slideCount;
        if (slideCount < imgs.length - 1) {
          count++;
          setSlideCount(count);
        } else {
          console.log("false");
          setSlideCount(0);
        }
      }, 7000);
    }
  };
  useEffect(() => {
    changeSlideCount(true);
    return () => {
      changeSlideCount(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideCount]);

  useEffect(() => {
    return () => {};
  });
  const imgArr = [];

  if (typeof window !== "undefined") {
    document.getElementsByClassName("slides");
  }
  const createImagesArray = () => {
    imgs.map((img) => imgArr.push(img));
  };
  createImagesArray();

  return (
    <>
      <CarouselWrapper>
        {imgs.map((image, idx) => (
          <StyledCarousel
            key={idx}
            active={idx === slideCount}
            className="slides"
          >
            <ImageContainer>
              <Image id="name" src={image} />
            </ImageContainer>
          </StyledCarousel>
        ))}
      </CarouselWrapper>
      <DotContainer>
        <StyledRectangle />
        {imgs.map((image, idx) => (
          <StyledRectangleDot key={idx} active={idx === slideCount} />
        ))}
      </DotContainer>
    </>
  );
};

export default Carousel;
