import React from "react";
import Modal from "react-bootstrap/Modal";
import "./Present.scss";

const Presentationmodal = (props) => {
  console.log("props", props);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="present-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.slideName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.slides &&
          props.slides.map((slideImg, idx) => (
            <div className="present-container">
              <img key={idx} src={slideImg} />
            </div>
          ))}
      </Modal.Body>
    </Modal>
  );
};

export default Presentationmodal;
