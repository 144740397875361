
import React from 'react';
import Clip from "../../components/Clip/Clip";
import Loader from "../../components/Loader/Loader";
import "./Styles.scss"

const MainComponent = ({pageData}) => {
  return pageData ? (
    <>
      <div className="main">
        <div className="main-container">
          <div className="main-section">
            {pageData.main?.welcomeImgs &&
              pageData.main?.welcomeImgs.map((i, idx) => (
                <div key={idx} className={`clip-container-${idx + 1}`}>
                  <Clip clipImg={i.img} />
                  <div className={`clip-text-${idx + 1}`}>{i.name}</div>
                </div>
              ))}
          </div>
          <div className="name-container">
            <div className="company-title">Lynnjac Consulting</div>
            <div className="company-ceo">Dr. Esther Jackson, CEO</div>
          </div>
        </div>

      </div>
    </>
  ) : (
    <Loader />
  );
}

export default MainComponent;              
                