
import React from 'react';
import "./Styles.scss"
import Loader from '../../components/Loader/Loader';
import Breadcrumb from '../../components/BreadCrumb/BreadCrumb';

const TrainingComponent = ({pageData, handleRoute, history}) => {
  return pageData ? (
    <>
      <div className="training">
        <div className="about-container">
          <div className="about-header">Training Materials</div>
        </div>
        <div className="courses-jumbotron"></div>
        <Breadcrumb crumb="Training Materials" />
        <div className="courses-description">
          Training is not the same as it was 30, 20, or even 10 years ago. So
          why should the materials you use be? According to authorities in the
          industry like ATD and Training Industry, learning content formats
          should be representative of the modern workplace. Lynnjac Consulting
          has a suite of format offerings with learning materials to cater to
          your needs.
          <div className="course-bar-section">
            <div
              className="contact-offer"
              onClick={() => handleRoute("contact", 'locally', history)}
            >
              Find out what training materials are available when you contact
              us.
            </div>

            <div className="courses-bar"></div>

            <div className="course-see-below">
              See below for available training materials
            </div>
          </div>
        </div>
        <div className="courses-grid-container">
          <ul id="categories" class="clr">
            <li class="pusher"></li>
            <li>
              <div onClick={() => handleRoute("contact", 'locally', history)}>
                <img src={pageData.training?.info} />
                <h1>Infographics</h1>
                <p>Contact Lynnjac Consulting today!</p>
              </div>
            </li>
            <li>
              <div onClick={() => handleRoute("contact", 'locally', history)}>
                <img src={pageData.training?.dei} />
                <h1>Learning Strategy/DEI Strategy</h1>
                <p>Contact Lynnjac Consulting today!</p>
              </div>
            </li>
            <li>
              <div onClick={() => handleRoute("contact", 'locally', history)}>
                <img src={pageData.training?.job} />
                <h1>Job Aids</h1>
                <p>Contact Lynnjac Consulting today!</p>
              </div>
            </li>
            <li>
              <div onClick={() => handleRoute("contact", 'locally', history)}>
                <img src={pageData.training?.facilitator} />
                <h1>Facilitator Guides & Participant Manuals</h1>
                <p>Contact Lynnjac Consulting today!</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
  
    </>
  ) : (
    <Loader />
  );

}

export default TrainingComponent;              
                