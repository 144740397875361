import React from "react";
import { useCoursesHook } from "./Hooks";
import CoursesComponent from "./Component";
import Header from "../../components/Header/Header";
import SpeakingEvents from "../../components/Speaking-Events/Speaking-Events";
import Footer from "../../components/Footer/Footer";

const Courses = () => {
  return (
    <>
      <Header />
      <CoursesComponent {...useCoursesHook()} />
      <SpeakingEvents />
      <Footer />
    </>
  );
};
export default Courses;
