import { useEffect, useState } from "react";

import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
} from "@cmj526/made-for-us";
import { appID } from "../../shared/app-service";

export const useBookHook = () => {
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    hasCookieAndStorage(appID, "book")
      ? setPageData(decode(appStore.get(`${appID}book`)))
      : setSessionData(appID, "book", "/api/book", setPageData);
  }, []);

  useEffect(() => {
    if (pageData) {
      startTyping();
    }
  }, [pageData]);

  const startTyping = () => {
    const text = document.getElementById("publish");
    let str = text.innerHTML;
    text.innerHTML = "";

    const speed = 50;
    let i = 0;

    const typeWriter = () => {
      if (i < str.length) {
        text.innerHTML += str.charAt(i);
        i++;
        setTimeout(typeWriter, speed);
      }
    };
    setTimeout(typeWriter, speed);
  };

  return {
    pageData,
  };
};
