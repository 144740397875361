import React, { useEffect, useState } from "react";
import { retrieve } from "../../shared/api";
import "./Speaking.scss";

const SpeakingEvents = () => {
  console.log("hi events");
  const [data, setData] = useState();
  const [events, setEvents] = useState({});

  useEffect(() => {
    retrieve("/api/speaking").then((res) => {
      console.log("speaking data", res.data);
      setData(res.data);
      setEvents(res.data.events);
    });
  }, []);
  return data ? (
    <div className="speaking">
      <div className="wrapper">
        <h1 className="section-title">
          <span>Upcoming Events</span>
        </h1>
        {events &&
          events.map((speaker, idx) => (
            <div className={`event-list-item ${speaker.ec}`}>
              <div className="event-info">
                <div className="event-info-extras">
                  <span className="iCal"></span>
                </div>
                <div className="event-date">
                  <p className="event-start">{speaker.date}</p>
                </div>
                <div className="event-description">
                  <div className="event-header">
                    <a href="#">
                      <h1 className="event-title">{speaker.event}</h1>
                    </a>
                    <p className="event-location">{speaker.location}</p>
                    <p className="event-time">{speaker.time}</p>
                    <a href={speaker.link} className="more">
                      view event
                    </a>
                  </div>
                  <div className="event-excerpt"></div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  ) : (
    ""
  );
};

export default SpeakingEvents;
