import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";

const navigationLinks = [
  {
    name: "About",
    to: "/about",
  },
  {
    name: "Courses & Workshops",
    to: "/courses",
  },
  {
    name: "Publishings & Literary Work",
    to: "/book",
  },
  {
    name: "Training Materials",
    to: "/training",
  },
  {
    name: "Contact",
    to: "/contact",
  },
];

const Header = () => {
  return (
    <div className="header-container">
      <Link to="/" className="header-logo">
        <img
          src="https://ljc-site.s3.us-east-2.amazonaws.com/img/lynnjac-logo.png"
          alt=""
        />
      </Link>
      <div className="link-container">
        {navigationLinks.map((link, idx) => (
          <Link key={idx} to={link.to} className="nav-links">
            {link.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Header;
