let shouldanimate = false;

const elementInView = (el, percentageScroll = 1) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop <=
    (window.innerHeight || document.documentElement.clientHeight) /
      percentageScroll
  );
};

const elementOutofView = (el) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop > (window.innerHeight || document.documentElement.clientHeight)
  );
};

export const handleScrollAnimation = (currentEl) => {
  if (currentEl) {
    if (elementInView(currentEl, 1.25)) {
      console.log("element in view");
      shouldanimate = true;
    }

    if (elementOutofView(currentEl)) {
      console.log("elemen not in view");
      shouldanimate = false;
    }
  }

  return shouldanimate;
};
