import React from "react";
import Breadcrumb from "../../components/BreadCrumb/BreadCrumb";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Loader from "../../components/Loader/Loader";
import "./Styles.scss";

const AboutComponent = ({pageData}) => {
  return pageData ? (
    <>
      <Header />
      <div className="about">
        <div className="about-container">
          <div className="about-header">Dr. Esther L. Jackson</div>
        </div>
        <Breadcrumb crumb="Dr. Esther L. Jacakson" />
        <section className="about-esther">
          <div className="about-name-container">
            <div className="about-name">
              About<span className="about-title">Dr. Esther L. Jackson</span>
            </div>
            <div className="about-description">
              <div className="about-me">
                <h4>Where can your potential take you?</h4>
                Dr. Esther Jackson helps clients discover the answer to this
                question. She has a Passion for leading others down the path of
                discovery to their limitless potential. She holds true to
                Accountability in all aspects of her work and personal values.
                She is driven to promote Adaptability at the forefront. This
                work guides people in their journey to be a model that they
                would follow.
              </div>

              <div className="about-me">
                <h4>Background</h4>
                Dr. Esther Jackson is a People Potential Accelerator who aligns
                learning needs to business strategy that leads to performance.
                With nearly 20 years in instructional design and facilitation,
                she has addressed learning needs in both the public sector and
                private sector. Born and raised in Michigan, she has worked for
                the City of Detroit and Innovative Learning Group along with
                projects supporting organizations in the automotive, education,
                financial, and manufacturing. Her background includes work with
                non-profit organizations. Some of the roles she has held are
                Organization/Employee Development Specialist, HR Training
                Specialist, HR Training Manager, Change Management Lead, and
                Project Manager & Instructional Designer.
                <div>
                  Dr. Jackson has worked in the diversity, equity and inclusion
                  space for six years in various capacities. The launch of her
                  training profession took her on the path of designing,
                  developing and instructing courses/workshops in diversity,
                  sensitivity awareness, and sexual harassment. This work
                  evolved with later roles that allowed her to work more
                  extensively in DEI with focus areas ranging from working with
                  employee resource group (ERG) leaders to consulting with
                  organizational leaders on DEI strategizing.
                </div>
                <div>
                  She has years of volunteer experience with the Association for
                  Talent Development (ATD) as a National Advisor for Chapters.
                  ATD is the largest association for talent development
                  professionals in the world. This work involved advising,
                  mentoring, and supporting ATD chapter leaders across the
                  country. In addition, she worked as the DEI Committee Lead in
                  her role. Expansion of her work over the years has taken her
                  beyond facilitation and into consulting, teaching, and
                  national speaking.
                </div>
              </div>

              <div className="about-me">
                <h4>Facilitator</h4>
                Dr. Jackson facilitates hard and soft skills training for
                conference sessions, workshops, webinars and retreats.
                Accountability, adaptability, diversity, emotional intelligence,
                and leadership development are just a few of the programs she
                has designed and facilitated for professional development at
                various organizational levels. In the academic environment, she
                instructs classes for adult learners. She has taught
                adaptability based on her book at the University of Pittsburgh
                and South Oregon University. ATD International Conference &
                Expo, BambooHR’s HR Virtual Summit, OH SHRM Conference, and
                Training Magazine Conference & Expo are national events where
                she has presented.
              </div>

              <div className="about-me">
                <h4>Volunteer</h4>
                As a volunteer, she works with the Association for Talent
                Development (ATD) as a National Advisor for Chapters. ATD is the
                largest association for talent development professionals in the
                world. This work involves advising, mentoring, and supporting
                ATD chapter leaders across the country. In addition, she works
                as the DEI Committee Lead in her role.
              </div>
            </div>
          </div>
          <div className="about-img">
            <img src={pageData.about?.profileImg} alt="" />
            <h3 className="about-msg">
              "In the midst of uncomfortable disruption, look for your
              opportunity for growth"
            </h3>
          </div>
        </section>
      </div>
      <Footer />
    </>
  ) : (
    <Loader />
  );

};

export default AboutComponent;
