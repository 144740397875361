import React from "react";
import { useContactHook } from "./Hooks";
import ContactComponent from "./Component";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const Contact = () => {
  return (
    <>
      <Header />
      <ContactComponent {...useContactHook()} />
      <Footer />
    </>
  );
};
export default Contact;
