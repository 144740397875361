
import React from 'react';
import {useTrainingHook} from './Hooks';
import TrainingComponent from './Component';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

const Training = () => {
  return (
    <>
      <Header/>
      <TrainingComponent {...useTrainingHook()} />
      <Footer />
    </>
  )
}
export default Training;           
                