import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
  handleRoute,
} from "@cmj526/made-for-us";
import { appID } from "../../shared/app-service";

export const useCoursesHook = () => {
  const [pageData, setPageData] = useState({});
  const videoEl = useRef(null);
  let history = useNavigate();

  const attemptPlay = () => {
    if (videoEl.current) {
      videoEl && videoEl.current.classList.add("video_animation");
      videoEl &&
        videoEl.current &&
        videoEl.current.play().catch((error) => {
          console.error("Error attempting to play", error);
        });
    }
  };

  const [modalShow, setModalShow] = useState(false);
  const [modalSlides, setModalSlides] = useState([]);
  const [modalSlideName, setModalSlideName] = useState("");

  const presentModal = (slideName) => {
    setModalShow(true);
    setModalSlides(pageData.courses[slideName]);
    setModalSlideName(slideName);
  };

  useEffect(() => {
    hasCookieAndStorage("courses")
      ? setPageData(decode(appStore.get(`${appID}courses`)))
      : setSessionData(appID,"courses", "/api/courses", setPageData);

    attemptPlay();
  }, []);

  return {
    pageData,
    modalShow,
    modalSlideName,
    modalSlides,
    setModalShow,
    presentModal,
    videoEl,
    handleRoute,
    history,
  };
};
