
import { useEffect, useState } from "react";
import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
} from "@cmj526/made-for-us";
import { appID } from "../../shared/app-service";

export const useContactHook = () => {
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    hasCookieAndStorage("contact")
      ? setPageData(decode(appStore.get(`${appID}contact`)))
      : setSessionData(appID, "contact", "/api/contact", setPageData);
  }, []);

  return {
    pageData,
  };
};          
                