
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  appStore,
  decode,
  hasCookieAndStorage,
  setSessionData,
  handleRoute
} from "@cmj526/made-for-us";
import { appID } from "../../shared/app-service";

export const useTrainingHook = () => {
  let history = useNavigate();
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    hasCookieAndStorage("training")
      ? setPageData(decode(appStore.get(`${appID}training`)))
      : setSessionData(appID, "training", "/api/training", setPageData);
  }, []);

  return {
    pageData,
    handleRoute,
    history
  };
};          
                