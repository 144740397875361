
import React from 'react';
import Breadcrumb from "../../components/BreadCrumb/BreadCrumb";
import "./Styles.scss"

const ContactComponent = ({pageData}) => {
  return (
    <>
      <div className="contact">
        <div className="about-container">
          <div className="about-header">Contact</div>
        </div>
        <Breadcrumb crumb="Contact" />
        <div className="contact-form-container">
          <div className="contact-form">
            <form
              action="mailto:estherljackson0926@@gmail.com"
              method="POST"
              enctype="multipart/form-data"
            >
              <div className="form-name">
                <input readOnly type="text" placeholder="Name" />
              </div>
              <div className="form-email">
                <input readOnly type="email" placeholder="Email" />
              </div>
              <div className="form-phone">
                <input readOnly type="text" placeholder="Phone" />
              </div>

              <div className="form-inquiry">
                <textarea
                  placeholder="Inquiry/message"
                  type="text"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>

              <div className="form-target">
                <input type="text" placeholder="Target Date" />
              </div>

              <div className="form-how">
                <input
                  readOnly
                  type="text"
                  placeholder="How did you hear about us ?"
                />
              </div>
              <input
                readOnly
                className="subm-btn"
                type="submit"
                value="Submit"
              />
            </form>
          </div>
        </div>
      </div>
    </>
  );

}

export default ContactComponent;              
                