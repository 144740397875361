
import {BehaviorSubject} from 'rxjs';

export const handleNext = new BehaviorSubject("");
let shouldanimate = false;

const elementInView = (el, percentageScroll = 1) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop <=
    (window.innerHeight || document.documentElement.clientHeight) /
      percentageScroll
  );
};

const elementOutofView = (el) => {
  const elementTop = el.getBoundingClientRect().top;

  return (
    elementTop > (window.innerHeight || document.documentElement.clientHeight)
  );
};



export const handleScrollAnimation = (currentEl) => {
  if (currentEl) {
    if (elementInView(currentEl, 1.25)) {
      shouldanimate = true;
    }

    if (elementOutofView(currentEl)) {
      shouldanimate = false;
    }
  }

  return shouldanimate;
};

export const handleRoute = (r, w, h) => {
  const route = {
    locally: () => (h ? h(r) : ""),
    external: () => window.open(r, "_blank"),
  };
  return route[w]();
};

export const appID = "_ljc_";
export const showMenu$ = new BehaviorSubject()



