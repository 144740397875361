
import React from 'react';
import Breadcrumb from "../../components/BreadCrumb/BreadCrumb";
import Loader from "../../components/Loader/Loader";
import "./Styles.scss"

const BookComponent = ({pageData}) => {
  return pageData ? (
    <>
      <div className="book">
        <div className="about-container">
          <div className="about-header">Publishings & Literary Work</div>
        </div>
        <Breadcrumb crumb="Publishings & Literary Work" />
        <div className="jumbotron"></div>
        <div id="publish" className="shadowed publish-text">
          "Do you think you’re really adapting? Adaptability in Talent
          Development is a must-have for your book collection. It’s time to find
          out what you got wrong with adaptability and steps to get it right.
          The surprise may be, it’s not as simple as you think. What seems like
          the worst of times can create the best of times if you are ready to
          adapt. Soft skills are making the hard impacts needed for today’s
          workplace. This is one crucial area where organizations are finding a
          competitive advantage and sustainability when we look at research from
          Gallup and McKinsey. Adaptability in Talent Development takes a deep
          dive to help you understand why. This resource is a part of the ATD
          Soft Skills Series available as a complete package."
        </div>
        <div className="container-for-books">
          <a className="book-card-wrap" href={pageData.book?.adaptability}>
            <div className="book-card">
              <div className="book-cardBg1"></div>
              <div className="cardInfo">
                <h3 className="cardTitle">Purchase Now!!</h3>
                <p>Adaptability in Talent Development</p>
              </div>
            </div>
          </a>
          <a className="book-card-Wrap" href={pageData.book?.atd}>
            <div className="book-card">
              <div className="book-cardBg"></div>
              <div className="cardInfo">
                <h3 className="cardTitle">Purchase Now!!</h3>
                <p>The ATD Soft Skills Series</p>
              </div>
            </div>
          </a>
        </div>
        <div className="book-articles">
          <div className="articles-bar-section">
            <div className="courses-bar"></div>
            <div className="course-see-below">See articles below</div>
          </div>

          <div className="articles-container">
            {pageData.book?.articles &&
              pageData.book?.articles.map((article, idx) => (
                <a key={idx} href={article.link}>
                  <img alt='books' src={article.img} />
                </a>
              ))}
          </div>
        </div>
      </div>
    </>
  ) : (
    <Loader />
  );

}

export default BookComponent;              
                