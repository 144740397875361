import axios from "axios";

export const retrieve = async (u) => {
  return await axios.get(u).catch((err) => {
    console.log("REQUEST ERROR ->", err);
  });
};

export const send = async (u, d) => {
  return await axios.post(u, d);
};
