
import React from 'react';
import {useMainHook} from './Hooks';
import MainComponent from './Component';
import Header from '../../components/Header/Header';
import PeopleSection from '../../components/People-Section/People-Section';
import ServiceSection from '../../components/Service-Section/Service-Section';
import QuoteSection from '../../components/Quote-Section/Quote-Section';

const Main = () => {
  return (
   <>
    <Header />
    <MainComponent {...useMainHook()} />
    <PeopleSection />
    <ServiceSection />
    <QuoteSection />
   </>
  )
}
export default Main;           
                